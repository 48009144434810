import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  Radio,
  Button,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import api from "../config/axios"; // Importing pre-configured axios instance

const DemoSignupModal = ({ isOpen, onClose }) => {
  const { colorMode } = useColorMode();
  const router = useRouter();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    userType: "candidate",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserTypeChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      userType: value,
    }));
  };

  // Function to send data to API
  const sendToAPI = async (data) => {
    try {
      const response = await api.post("/insert_demo_customer", {
        customer_name: data.name,
        email: data.email,
        mobile_no: data.phone,
        company_name: data.company,
        company_type: data.userType.charAt(0).toUpperCase() + data.userType.slice(1), // Capitalize first letter
      });

      return response.data;
    } catch (error) {
      console.error("API submission error:", error);
      throw error;
    }
  };

  // Updated handleSubmit function with API call
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Validation
    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.userType
    ) {
      toast({
        title: "Error",
        description: "Please fill in all required fields",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    // Email validation with domain restrictions for recruiters and interviewers
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const personalEmailDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
      "icloud.com",
      "protonmail.com",
    ];

    // Check email format
    if (!emailRegex.test(formData.email)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    // Extract domain from email
    const emailDomain = formData.email.split("@")[1].toLowerCase();

    // Validate domain for recruiters and interviewers
    if (
      (formData.userType === "recruiter" ||
        formData.userType === "interviewer") &&
      personalEmailDomains.includes(emailDomain)
    ) {
      toast({
        title: "Error",
        description:
          "Please use a company email address for recruiters and interviewers",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsSubmitting(false);
      return;
    }

    try {
      // Store in session storage
      sessionStorage.setItem("formData", JSON.stringify(formData));
      
      // Send data to API
      const apiResponse = await sendToAPI(formData);
      
      toast({
        title: "Success",
        description: "Your demo request has been submitted successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      
      // Navigate to demo page
      router.push("/demoPage");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to submit your request. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent bg={colorMode === "dark" ? "gray.800" : "white"}>
        <ModalHeader textAlign="center">Request a Demo</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} as="form" onSubmit={handleSubmit}>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your name"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Input
                name="phone"
                type="tel"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Company Name</FormLabel>
              <Input
                name="company"
                value={formData.company}
                onChange={handleInputChange}
                placeholder="Enter your company name"
              />
            </FormControl>

            <FormControl as="fieldset" isRequired>
              <FormLabel as="legend">I am a</FormLabel>
              <RadioGroup
                value={formData.userType}
                onChange={handleUserTypeChange}
              >
                <VStack align="start">
                  <Radio value="recruiter">Recruiter</Radio>
                  <Radio value="interviewer">Interviewer</Radio>
                  <Radio value="trainer">Trainer</Radio>
                  <Radio value="candidate">Candidate</Radio>
                </VStack>
              </RadioGroup>
            </FormControl>

            <Button
              type="submit"
              colorScheme="blue"
              width="full"
              isLoading={isSubmitting}
              loadingText="Submitting"
            >
              Submit
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DemoSignupModal;